import {createSlice} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/es/storage'

interface UserInfo {
  userId?: number
  userName?: string
  userEmail?: string
  orgId?: number
  orgName?: string
  role?: string
  isEnableLiveRecording?: boolean
  isEnableLiveStreaming?: boolean
  isEnableManualTracking?: boolean
  isEnableScreenshotBlurEffect?: boolean
  isIntegrated: boolean
  projectTaskConfig?: boolean
  orgCutOffTime?: string | number
  planExpiry?: string
  isSuspended?: boolean
  isGroupAdmin?: boolean
  isViewScreenShotEnable?: boolean
  organizationStatus?: string
  selectedUserName?: string
  selectedUserId?: number
  selectedUserEmail?: string
  userCutOffTime?: string | number
  groupAdmin?: Array<{
    groupAdminId: string
    groupAdminName: string
    groupAdminEmail: string
    isActive: boolean
    roleId: string
    roleName: string
    organizations: Array<{
      organizationId: string
      organizationName: string
    }>
  }>
}

const initialUserInfo: UserInfo = {
  userId: undefined,
  userName: undefined,
  userEmail: undefined,
  orgId: undefined,
  orgName: undefined,
  role: undefined,
  isEnableLiveRecording: false,
  isEnableLiveStreaming: false,
  isEnableManualTracking: false,
  isEnableScreenshotBlurEffect: false,
  projectTaskConfig: false,
  orgCutOffTime: undefined,
  planExpiry: undefined,
  isSuspended: false,
  isGroupAdmin: false,
  isIntegrated: false,
  isViewScreenShotEnable: false,
  organizationStatus: undefined,
  groupAdmin: [],
  selectedUserName: undefined,
  selectedUserId: undefined,
  selectedUserEmail: undefined,
  userCutOffTime: undefined,
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: initialUserInfo,
  reducers: {
    updateUserInfo(state, action) {
      state.userId = action.payload.userId
      state.userName = action.payload.fullName
      state.userEmail = action.payload.email
      state.role = action.payload.roleName
      state.orgId = action.payload.organizationId
      state.orgName = action.payload.organizationName
      state.isEnableLiveRecording = action.payload.isEnableLiveRecording
      state.isEnableLiveStreaming = action.payload.isEnableLiveStreaming
      state.isEnableManualTracking = action.payload.isEnableManualTracking
      state.isEnableScreenshotBlurEffect = action.payload.isEnableScreenshotBlurEffect
      state.projectTaskConfig = action.payload.projectTaskConfig
      state.orgCutOffTime = action.payload.orgCutOffTime
      state.isSuspended = action.payload.isSuspended
      state.planExpiry = action.payload.planExpiryEndDate
      state.organizationStatus = action.payload.organizationStatus
      state.isGroupAdmin = action.payload.isGroupAdmin
      state.userCutOffTime = action.payload.userCutOffTime
      state.isIntegrated = action.payload.isIntegrated
      state.isViewScreenShotEnable = action.payload.isViewScreenShotEnable
    },
    updateGroupAdminInfo(state, action) {
      state.orgId = action.payload.organizationId
      state.orgName = action.payload.organizationName
      state.isEnableLiveRecording = action.payload.isEnableLiveRecording
      state.isEnableLiveStreaming = action.payload.isEnableLiveStreaming
      state.isEnableManualTracking = action.payload.isEnableManualTracking
      state.isEnableScreenshotBlurEffect = action.payload.isEnableScreenshotBlurEffect
      state.projectTaskConfig = action.payload.projectTaskConfig
      state.orgCutOffTime = action.payload.orgCutOffTime
      state.isSuspended = action.payload.isSuspended
      state.planExpiry = action.payload.planExpiryEndDate
      state.organizationStatus = action.payload.organizationStatus
    },
    setGroupAdmin(state, action) {
      state.groupAdmin = action.payload
    },
    setGroupAdminStatus(state, action) {
      state.isGroupAdmin = action.payload
    },
    setPlanDetails(state, action) {
      state.isSuspended = action.payload.isSuspended
      state.planExpiry = action.payload.expiryDate
      state.organizationStatus = action.payload.organizationStatus

      // state.isGroupAdmin = action.payload
    },
    updateSelectedUser(state, action) {
      state.selectedUserName = action.payload.selectedUserName
      state.selectedUserId = action.payload.selectedUserId
      state.selectedUserEmail = action.payload.selectedUserEmail
    },
    removeSelectedUser(state, action) {
      state.selectedUserName = undefined
      state.selectedUserId = undefined
      state.selectedUserEmail = undefined
    },
    resetUserInfo(state) {
      Object.assign(state, initialUserInfo)
    },
  },
})

export const persistConfig = {
  key: 'data',
  storage,
}

export const persistedReducer = persistReducer(persistConfig, userInfoSlice.reducer)

export const userInfoActions = userInfoSlice.actions
