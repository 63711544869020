/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import '../../../../css/orgPopUp.css'
import {KTSVG} from '../../../../_metronic/helpers'
import {ErrorModal, Loader} from '../../../../app/CommonFunctions/CommonFunction'
import '../../../../css/ProVsIdlePopUp.css'
import {GetGroupAdminById, GetOrgnizationDetails} from '../../../services/GroupAdmin.services'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {useNavigate} from 'react-router-dom'
import {userInfoActions} from '../redux/UserInfoRedux'

interface PopupProps {
  onClose: () => void
  adminId: string
  accessToken: string
}

const OrgSelection: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [spinner, setSpinner] = useState(false)
  const [organization, setOrganization] = useState<[]>([])

  const UserData: any = useSelector<RootState>(({data}) => data.userId, shallowEqual)
  const accessToken: any = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const orgId = localStorage.getItem('org_Id')

  useEffect(() => {
    //to remove the selected user of the previous org
    dispatch(userInfoActions.removeSelectedUser({}))
    getAdminDetails()
  }, [])

  const getAdminDetails = async () => {
    try {
      setSpinner(true)
      const adminDetails = await GetGroupAdminById(UserData, accessToken)
      setOrganization(adminDetails.data.organizations)
      setSpinner(false)
    } catch (error) {
      ErrorModal()
    }
  }

  const handleOrganizationSelect = async (org: any) => {
    try {
      const getOrgDetials = await GetOrgnizationDetails(org.organizationId, accessToken)
      localStorage.setItem('org_Id', getOrgDetials.data.organizationId)
      localStorage.setItem('org_name', getOrgDetials.data.organizationName)
      localStorage.setItem('ProjTaskConfig', getOrgDetials.data.projectTaskConfig)
      localStorage.setItem('timezone', getOrgDetials.data.orgTimeZoneId)
      dispatch(userInfoActions.updateGroupAdminInfo(getOrgDetials.data))
      dispatch(userInfoActions.setGroupAdminStatus(false))
      navigate('/builder')
    } catch (error) {
      ErrorModal()
    }
  }

  const handleBackClick = () => {
    navigate('/builder')
  }

  return (
    <div
      className='card mb-5 mb-xl-8 w-100 h-100'
      style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', zIndex: '99999'}}
    >
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            Please select an organization to continue
          </span>
        </h3>

        {orgId !== 'null' && (
          <div
            className='d-flex'
            style={{justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
            onClick={handleBackClick}
          >
            <KTSVG path='/media/icons/duotune/art/back.svg' className='svg-icon-3' />
          </div>
        )}
      </div>
      <div
        className='h-100 d-flex '
        style={{
          background: '#E8E8E8',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          paddingTop: '5rem',
        }}
      >
        {spinner && Loader('0px')}
        {!spinner &&
          organization.map((orgData: any, index) => (
            <div className='org-card' key={index}>
              <div className='org-card__img'>
                <svg xmlns='http://www.w3.org/2000/svg' width='100%'>
                  <rect fill='#ffffff' width='540' height='450'></rect>
                  <defs>
                    <linearGradient
                      id='a'
                      gradientUnits='userSpaceOnUse'
                      x1='0'
                      x2='0'
                      y1='0'
                      y2='100%'
                      gradientTransform='rotate(222,648,379)'
                    >
                      <stop offset='0' stop-color='#ffffff'></stop>
                      <stop offset='1' stop-color='#FC726E'></stop>
                    </linearGradient>
                    <pattern
                      patternUnits='userSpaceOnUse'
                      id='b'
                      width='300'
                      height='250'
                      x='0'
                      y='0'
                      viewBox='0 0 1080 900'
                    >
                      <g fill-opacity='0.5'>
                        <polygon fill='#444' points='90 150 0 300 180 300'></polygon>
                        <polygon points='90 150 180 0 0 0'></polygon>
                        <polygon fill='#AAA' points='270 150 360 0 180 0'></polygon>
                        <polygon fill='#DDD' points='450 150 360 300 540 300'></polygon>
                        <polygon fill='#999' points='450 150 540 0 360 0'></polygon>
                        <polygon points='630 150 540 300 720 300'></polygon>
                        <polygon fill='#DDD' points='630 150 720 0 540 0'></polygon>
                        <polygon fill='#444' points='810 150 720 300 900 300'></polygon>
                        <polygon fill='#FFF' points='810 150 900 0 720 0'></polygon>
                        <polygon fill='#DDD' points='990 150 900 300 1080 300'></polygon>
                        <polygon fill='#444' points='990 150 1080 0 900 0'></polygon>
                        <polygon fill='#DDD' points='90 450 0 600 180 600'></polygon>
                        <polygon points='90 450 180 300 0 300'></polygon>
                        <polygon fill='#666' points='270 450 180 600 360 600'></polygon>
                        <polygon fill='#AAA' points='270 450 360 300 180 300'></polygon>
                        <polygon fill='#DDD' points='450 450 360 600 540 600'></polygon>
                        <polygon fill='#999' points='450 450 540 300 360 300'></polygon>
                        <polygon fill='#999' points='630 450 540 600 720 600'></polygon>
                        <polygon fill='#FFF' points='630 450 720 300 540 300'></polygon>
                        <polygon points='810 450 720 600 900 600'></polygon>
                        <polygon fill='#DDD' points='810 450 900 300 720 300'></polygon>
                        <polygon fill='#AAA' points='990 450 900 600 1080 600'></polygon>
                        <polygon fill='#444' points='990 450 1080 300 900 300'></polygon>
                        <polygon fill='#222' points='90 750 0 900 180 900'></polygon>
                        <polygon points='270 750 180 900 360 900'></polygon>
                        <polygon fill='#DDD' points='270 750 360 600 180 600'></polygon>
                        <polygon points='450 750 540 600 360 600'></polygon>
                        <polygon points='630 750 540 900 720 900'></polygon>
                        <polygon fill='#444' points='630 750 720 600 540 600'></polygon>
                        <polygon fill='#AAA' points='810 750 720 900 900 900'></polygon>
                        <polygon fill='#666' points='810 750 900 600 720 600'></polygon>
                        <polygon fill='#999' points='990 750 900 900 1080 900'></polygon>
                        <polygon fill='#999' points='180 0 90 150 270 150'></polygon>
                        <polygon fill='#444' points='360 0 270 150 450 150'></polygon>
                        <polygon fill='#FFF' points='540 0 450 150 630 150'></polygon>
                        <polygon points='900 0 810 150 990 150'></polygon>
                        <polygon fill='#222' points='0 300 -90 450 90 450'></polygon>
                        <polygon fill='#FFF' points='0 300 90 150 -90 150'></polygon>
                        <polygon fill='#FFF' points='180 300 90 450 270 450'></polygon>
                        <polygon fill='#666' points='180 300 270 150 90 150'></polygon>
                        <polygon fill='#222' points='360 300 270 450 450 450'></polygon>
                        <polygon fill='#FFF' points='360 300 450 150 270 150'></polygon>
                        <polygon fill='#444' points='540 300 450 450 630 450'></polygon>
                        <polygon fill='#222' points='540 300 630 150 450 150'></polygon>
                        <polygon fill='#AAA' points='720 300 630 450 810 450'></polygon>
                        <polygon fill='#666' points='720 300 810 150 630 150'></polygon>
                        <polygon fill='#FFF' points='900 300 810 450 990 450'></polygon>
                        <polygon fill='#999' points='900 300 990 150 810 150'></polygon>
                        <polygon points='0 600 -90 750 90 750'></polygon>
                        <polygon fill='#666' points='0 600 90 450 -90 450'></polygon>
                        <polygon fill='#AAA' points='180 600 90 750 270 750'></polygon>
                        <polygon fill='#444' points='180 600 270 450 90 450'></polygon>
                        <polygon fill='#444' points='360 600 270 750 450 750'></polygon>
                        <polygon fill='#999' points='360 600 450 450 270 450'></polygon>
                        <polygon fill='#666' points='540 600 630 450 450 450'></polygon>
                        <polygon fill='#222' points='720 600 630 750 810 750'></polygon>
                        <polygon fill='#FFF' points='900 600 810 750 990 750'></polygon>
                        <polygon fill='#222' points='900 600 990 450 810 450'></polygon>
                        <polygon fill='#DDD' points='0 900 90 750 -90 750'></polygon>
                        <polygon fill='#444' points='180 900 270 750 90 750'></polygon>
                        <polygon fill='#FFF' points='360 900 450 750 270 750'></polygon>
                        <polygon fill='#AAA' points='540 900 630 750 450 750'></polygon>
                        <polygon fill='#FFF' points='720 900 810 750 630 750'></polygon>
                        <polygon fill='#222' points='900 900 990 750 810 750'></polygon>
                        <polygon fill='#222' points='1080 300 990 450 1170 450'></polygon>
                        <polygon fill='#FFF' points='1080 300 1170 150 990 150'></polygon>
                        <polygon points='1080 600 990 750 1170 750'></polygon>
                        <polygon fill='#666' points='1080 600 1170 450 990 450'></polygon>
                        <polygon fill='#DDD' points='1080 900 1170 750 990 750'></polygon>
                      </g>
                    </pattern>
                  </defs>
                  <rect x='0' y='0' fill='url(#a)' width='100%' height='100%'></rect>
                  <rect x='0' y='0' fill='url(#b)' width='100%' height='100%'></rect>
                </svg>
              </div>
              <div className='org-card__avatar'>
                <div>
                  <span style={{fontSize: '3rem', fontWeight: 500, color: 'beige'}}>
                    {orgData.organizationName
                      .split(' ', 2)
                      .map((word: any) => word[0])
                      .join('')
                      .toUpperCase()}
                  </span>
                </div>
              </div>
              <div className='org-card__title'>{orgData.organizationName}</div>

              <div className='card__wrapper'>
                <button
                  className={'org-card__btn'}
                  onClick={() => handleOrganizationSelect(orgData)}
                >
                  Proceed
                </button>
              </div>
            </div>
          ))}
      </div>

      {/* begin::Body */}
    </div>
  )
}

export {OrgSelection}
