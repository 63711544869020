import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import {AlertModal, MultiDateTimePicker} from '../../CommonFunctions/CommonFunction'
import axios, {CancelTokenSource} from 'axios'
import {
  callAllManager,
  departmentInfoByOrg,
  memberInfoByOrg,
} from '../../modules/auth/redux/AuthCRUD'
import dayjs from 'dayjs'
import {ProdAndUnCompo} from './ProdAndUnCompo'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth/redux/UserInfoRedux'
import {start} from 'repl'
import {DownloadOverviewReport} from '../../services/common.services'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'

const API_URL = process.env.REACT_APP_API_URL

let cancelTokenSourceProductive: CancelTokenSource | null = null
let cancelTokenSourceUnprodtive: CancelTokenSource | null = null

const Pro_vs_Unpro: React.FC = () => {
  const role = localStorage.getItem('role')
  const [alignment, setAlignment] = React.useState('Individual')
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  //const [disableReactSelect, setDisableReactSelect] = useState<boolean>(false)
  const [currDisplayState, setCurrDisplayState] = useState<any>('Individual')
  const [memberError, setMemberError] = useState<boolean>(false)
  const [dateError, setDateError] = useState<any>(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [listOfProdWebAndApp, setListProdOfWebAndApp] = useState<any>([])
  const [listOfUnWebAndApp, setListOfUnWebAndApp] = useState<any>([])
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  useEffect(() => {
    setScreenLoader(true)

    const formattedDate = dayjs().format('YYYY-MM-DDT00:00:00[Z]')
    const productiveUnproductiveCalls = (userId: string) => {
      CallOfProductiveWebAndApp(userId, formattedDate, formattedDate, 'productive', '')
      CallOfProductiveWebAndApp(userId, formattedDate, formattedDate, 'unproductive', '')
    }

    if (ConfigDetails?.selectedUserName || role == process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      setAlignment('Individual')
      setCurrDisplayState('Individual')

      if (ConfigDetails.role === process.env.REACT_APP_SYSTEM_FIRST_ROLE) {
        callAllMemberAPI()
      } else if (ConfigDetails.role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
        callMemUnderManager()
      }

      const userId = ConfigDetails.selectedUserId || ConfigDetails.userId
      const userName = ConfigDetails.selectedUserName || ConfigDetails.userName

      setOptionSelectedMember({
        label: userName,
        value: userId,
      })

      productiveUnproductiveCalls(userId)
    }
    else {
      setCurrDisplayState('Organization')
      setAlignment('Organization')
      sendData('All', startDate, endDate)
    }

  }, [])

  const cancelOngoingRequests = () => {
    if (cancelTokenSourceProductive) {
      cancelTokenSourceProductive.cancel('New request initiated')
    }
    if (cancelTokenSourceUnprodtive) {
      cancelTokenSourceUnprodtive.cancel('New request initiated')
    }
  }

  const resetStateForNewSelection = (selectedValue: string) => {
    dispatch(
      userInfoActions.updateSelectedUser({
        selectedUserName: '',
        selectedUserId: '',
      })
    )

    setAlignment(selectedValue)
    setListProdOfWebAndApp([])
    setListOfUnWebAndApp([])
    setOptionSelectedMember(null)
    setCurrDisplayState(selectedValue)
  }

  const handleMemberError = (isError: boolean = true) => {
    setMemberError(isError)
  }

  const handleAPICalls = (selectedValue: string) => {
    switch (selectedValue) {
      case 'Individual':
        handleMemberError()
        if (ConfigDetails.role === 'Admin' || ConfigDetails.role === 'Report Admin') {
          callAllMemberAPI()
        } else if (ConfigDetails.role === 'Manager') {
          callMemUnderManager()
        }
        break
      case 'Team':
        handleMemberError()
        callAllManagerAPI()
        break
      case 'Department':
        handleMemberError()
        calAllDepartAPI()
        break
      case 'Organization':
        handleMemberError(false)
        sendData('All', startDate, endDate)
        break
      default:
        break
    }
  }

  const handleChange = (event: any) => {
    const selectedValue = event.target.value

    cancelOngoingRequests()

    // Only proceed if the state is changing
    if (currDisplayState !== selectedValue) {
      resetStateForNewSelection(selectedValue)
      handleAPICalls(selectedValue)
    }
  }

  const calAllDepartAPI = () => {
    departmentInfoByOrg().then((res) => {
      const newList = res.data.map((item: any) => {
        return {
          label: item.department,
          value: item.departmentId,
        }
      })
      setMemberOption(newList)
    })
  }

  const mapResponseData = (data: any[]) => {
    return data.map((item: any) => {
      return {
        label: item.fullName,
        value: item.userId,
      }
    })
  }

  const callAllMemberAPI = () => {
    memberInfoByOrg().then((res) => {
      const newList = mapResponseData(res.data)
      setMemberOption(newList)
    })
  }

  const callAllManagerAPI = () => {
    callAllManager().then((res) => {
      const newList = mapResponseData(res.data)
      setMemberOption(newList)
    })
  }

  const earlyStageCall = () => {
    if (cancelTokenSourceProductive) {
      cancelTokenSourceProductive.cancel('New request initiated')
    }
    if (cancelTokenSourceUnprodtive) {
      cancelTokenSourceUnprodtive.cancel('New request initiated')
    }

    setListProdOfWebAndApp([])
    setListOfUnWebAndApp([])
    setScreenLoader(true)
  }

  const sendData = (userValue: any, start: any = null, end: any = null) => {
    setMemberError(false)
    earlyStageCall()

    // Validate dates
    const startDateValue = start ?? startDate
    const endDateValue = end ?? endDate ?? startDateValue
    if (!startDateValue || !endDateValue) {
      setDateError(true)
      setScreenLoader(false)
      return
    }

    if (!userValue && alignment !== 'Organization' && currDisplayState !== 'Organization') {
      setMemberError(true)
      setScreenLoader(false)
      return
    }

    // Determine user value to be used based on current display state and input value
    const memberValue =
      userValue || (alignment !== 'Organization' ? optionSelectedMember?.value : 'All')
    const lastDate = dayjs(endDateValue).format('YYYY-MM-DDT00:00:00[Z]')
    const startDateFormatted = dayjs(startDateValue).format('YYYY-MM-DDT00:00:00[Z]')

    // Call the API based on current display state
    const isDepartment = currDisplayState === 'Department' && memberValue !== 'All'

    CallOfProductiveWebAndApp(
      isDepartment ? '' : memberValue,
      startDateFormatted,
      lastDate,
      'productive',
      isDepartment ? memberValue : ''
    )

    CallOfProductiveWebAndApp(
      isDepartment ? '' : memberValue,
      startDateFormatted,
      lastDate,
      'unproductive',
      isDepartment ? memberValue : ''
    )
  }

  const callMemUnderManager = () => {
    //callMemberUnderManager()
    GetAssignee()
      .then((res: any) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.assigneeName,
            value: item.assigneeId,
          }
        })
        setMemberOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const CallOfProductiveWebAndApp = <T,>(
    userId?: T,
    beginDate?: T,
    lastDate?: T,
    category?: T,
    depart?: T
  ) => {
    if (category === 'productive') cancelTokenSourceProductive = axios.CancelToken.source()

    if (category === 'unproductive') cancelTokenSourceUnprodtive = axios.CancelToken.source()

    let body
    if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
      body = {
        OrganizationId: localStorage.getItem('org_Id'),
        UserId: userId ? userId : localStorage.getItem('userId'),
        DepartmentId: depart,
        FromDate: beginDate,
        ToDate: lastDate,
        CategoryName: category,
      }
    } else {
      body = {
        organizationId: localStorage.getItem('org_Id'),
        userId: userId ? userId : localStorage.getItem('userId'),
        fromDate: beginDate,
        toDate: lastDate,
        departmentId: depart,
        categoryName: category,
      }
    }
    let fetch = ''
    if (userId === 'All') {
      fetch = 'OverviewReportForOrganization'
    } else if (currDisplayState === 'Individual' || ConfigDetails.selectedUserId) {
      fetch = 'OverviewReportForUser'
    } else if (currDisplayState === 'Team') {
      fetch = userId === 'All' ? 'OverviewReportForOrganization' : 'OverviewReportForManager'
    } else if (currDisplayState === 'Organization') {
      fetch = 'OverviewReportForOrganization'
    } else if (currDisplayState === 'Department') {
      fetch =
        role === process.env.REACT_APP_SYSTEM_SECOND_ROLE
          ? 'OverviewReportForReportManagerandDeptId'
          : 'OverviewReportForDepartment'
    } else {
      fetch = 'OverviewReportForOrganization'
    }

    axios
      .get(`${API_URL}/OverviewReport/${fetch}`, {
        params: body,
        cancelToken:
          category === 'productive'
            ? cancelTokenSourceProductive?.token
            : cancelTokenSourceUnprodtive?.token,
      })
      .then((res) => {
        if (!Array.isArray(res.data)) {
          category === 'productive'
            ? setListProdOfWebAndApp([res.data])
            : setListOfUnWebAndApp([res.data])
          setScreenLoader(false)
        } else {
          category === 'productive'
            ? setListProdOfWebAndApp(res.data)
            : setListOfUnWebAndApp(res.data)

          setScreenLoader(false)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    if (start !== null) {
      setDateError(false)
      sendData(alignment === 'Organization' ? 'All' : optionSelectedMember.value, start, end)
    } else {
      setDateError(true)
    }
  }

  const createExcel = async () => {
    try {
      setLoading(true)
      let body = {
        OrganizationId: ConfigDetails.orgId,
        UserId: '',
        DepartmentId: '',
        FromDate: dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        ToDate: endDate
          ? dayjs(endDate).format('YYYY-MM-DDT00:00:00[Z]')
          : dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'),
        SelectionType: '',
      }

      switch (alignment) {
        case 'Organization':
          body.SelectionType = 'organization'
          break
        case 'Team':
          body.SelectionType = 'manager'
          body.UserId = optionSelectedMember.value
          break
        case 'Individual':
          body.SelectionType = 'user'
          body.UserId = optionSelectedMember.value
          break
        case 'Department':
          if (role === process.env.REACT_APP_SYSTEM_SECOND_ROLE) {
            body.SelectionType = 'manageranddepartment'
            body.UserId = ConfigDetails.userId
          } else {
            body.SelectionType = 'department'
          }
          body.DepartmentId = optionSelectedMember.value
          break
        default:
          break
      }

      const getExcelData = await DownloadOverviewReport(body)

      const workbook = new ExcelJS.Workbook()

      // Check if the API data is empty
      if (getExcelData.data.length === 0) {
        // Create an empty worksheet
        const worksheet = workbook.addWorksheet('Empty Report')
        worksheet.columns = [{header: 'No Data Available', key: 'noData', width: 30}]
        worksheet.addRow(['No data found for the selected criteria.'])
      } else {
        // Process the data if available
        getExcelData.data.forEach((report: any) => {
          const {reportDate, users} = report

          users.forEach((user: any) => {
            const {fullName, data: userData} = user

            const worksheet = workbook.addWorksheet(
              `${dayjs(reportDate).format('DD-MM-YYYY')}_${fullName}`
            )
            worksheet.columns = [
              {header: 'App Name', key: 'appName', width: 20},
              {header: 'Url', key: 'url', width: 30},
              {header: 'Time', key: 'time', width: 15},
              {header: 'Type', key: 'type', width: 15},
            ]

            // Add the data
            userData.forEach((entry: any) => {
              if (entry.appName) {
                worksheet.addRow({
                  appName: entry.appName,
                  url: '',
                  time: convertSecIntoHoursMinSec(entry.totalTime),
                  type: entry.categoryType,
                })
              }
              if (entry.url) {
                worksheet.addRow({
                  appName: '',
                  url: entry.url,
                  time: convertSecIntoHoursMinSec(entry.totalTime),
                  type: entry.categoryType,
                })
              }
            })

            // Apply bold style to the header row
            worksheet.getRow(1).font = {bold: true}

            // Apply borders to all cells
            worksheet.eachRow({includeEmpty: true}, (row) => {
              row.eachCell({includeEmpty: true}, (cell) => {
                cell.border = {
                  top: {style: 'thin'},
                  left: {style: 'thin'},
                  bottom: {style: 'thin'},
                  right: {style: 'thin'},
                }
              })
            })
          })
        })
      }

      const filename = `Productive vs Unproductive${dayjs(startDate).format('DD/MM/YYYY')}-${
        endDate ? dayjs(endDate).format('DD/MM/YYYY') : dayjs(startDate).format('DD/MM/YYYY')
      }.xlsx`

      const wbout = await workbook.xlsx.writeBuffer()
      const blob = new Blob([wbout], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      setLoading(false)
      saveAs(blob, filename)
    } catch (error) {
      console.log(error)
      setLoading(false)
      AlertModal('Something went wrong.', '', 'error', false, '#7066E0', 'Ok')
    }
  }

  return (
    <>
      <div className='card mb-xl-7'>
        {/* begin::Header */}
        <div className='card-header border-0 py-5'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Productive VS Unproductive</span>
            </h3>

            <div className='d-flex'>
              <div className=' d-flex' style={{marginRight: '10px', height: '3.2rem'}}>
                {role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ? (
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    //exclusive
                    onChange={handleChange}
                    aria-label='Platform'
                  >
                    <ToggleButton value='Individual'>User</ToggleButton>
                    <ToggleButton value='Department'>Department</ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    //exclusive
                    onChange={handleChange}
                    aria-label='Platform'
                  >
                    <ToggleButton value='Organization'>Organization</ToggleButton>
                    <ToggleButton value='Team'>Team</ToggleButton>
                    <ToggleButton value='Individual'>Individual</ToggleButton>
                    <ToggleButton value='Department'>Department</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </div>

              <div
                style={{marginRight: '10px', width: '200px'}}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title={alignment == 'Team' ? 'Search Manager' : 'Search Member'}
              >
                <Select
                  components={makeAnimated()}
                  value={
                    alignment === 'Organization'
                      ? {label: 'All Members', value: 'All'}
                      : optionSelectedMember
                  }
                  isDisabled={alignment == 'Organization'}
                  options={memberOption}
                  placeholder={alignment == 'Department' ? 'Select Department' : 'Select Member'}
                  onChange={(item: any) => {
                    setOptionSelectedMember(item)
                    if (
                      alignment == 'Individual' &&
                      (role == process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                        role == process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                        role == process.env.REACT_APP_SYSTEM_SECOND_ROLE)
                    ) {
                      dispatch(
                        userInfoActions.updateSelectedUser({
                          selectedUserName: item.label,
                          selectedUserId: item.value,
                        })
                      )
                    }
                    sendData(item.value, startDate, endDate)
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
                {memberError && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    {alignment == 'Department'
                      ? 'Please Search Department'
                      : 'Please Search Member'}
                  </span>
                )}
              </div>

              <div className='d-flex flex-column'>
                {MultiDateTimePicker(
                  startDate,
                  endDate,
                  handleDateChange,
                  'form-control custom-Height'
                )}

                {dateError === true && (
                  <span className='text-danger' style={{marginLeft: '1rem'}}>
                    Please Select Date
                  </span>
                )}
              </div>
              <div className='d-flex flex-column' style={{marginLeft: '1rem'}}>
                <button
                  className='btn btn-primary d-flex align-items-center'
                  style={{height: '3rem'}}
                  onClick={(e) => createExcel()}
                  disabled={
                    memberError ||
                    dateError ||
                    !startDate ||
                    (listOfProdWebAndApp.length == 0 && listOfUnWebAndApp.length === 0)
                  }
                >
                  {!loading && (
                    <>
                      <i className='fa fa-download'></i>Download
                    </>
                  )}
                  {loading && (
                    <div className='d-flex'>
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Downloading
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-wrap justify-content-around my-5'>
        <ProdAndUnCompo
          data={listOfProdWebAndApp}
          title='Productive Web And App'
          loader={screenloader}
        />
        <ProdAndUnCompo
          data={listOfUnWebAndApp}
          title='Unproductive Web And App'
          loader={screenloader}
        />
      </div>
    </>
  )
}

export default Pro_vs_Unpro
