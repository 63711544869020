import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function SyncRedmineByOrganization(org_Id: string) {
  return axios.get(`${API_URL}/PortalIntegrationApi/SyncRedmineByOrganization?orgId=${org_Id}`)
}

export function SyncRedmineByUser(userId: string) {
  return axios.get(`${API_URL}/PortalIntegrationApi/SyncRedmineByUser?userId=${userId}`)
}
