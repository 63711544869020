import React, {useState, useEffect} from 'react'
import {
  getRoles,
  companyInfo,
  departmentInfoByOrg,
  getTimeZone,
} from '../../../modules/auth/redux/AuthCRUD'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  AlertModal,
  ErrorModal,
  GetActiveInactiveUser,
  baseMemberSchema,
} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'
import Switch from 'react-switch'
import dayjs from 'dayjs'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

const API = process.env.REACT_APP_API_URL

const phone = /^\+?(\d{1,5}[-\s]?){0,1}(\d{6}|\d{10,12})$/

const letters = /^[a-zA-Z0-9 '][a-zA-Z0-9 ']*$/

const EmployeeDetails: React.FC = () => {
  const role = localStorage.getItem('role')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState<any[]>([])
  const [manager, setManager] = useState<any[]>([])
  const [checked, setChecked] = React.useState(false)
  const [companies, setCompanies] = useState<any[]>([])
  const [timeZone, setTimeZone] = useState<any[]>([])
  const loadtoggle = role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE ? false : true

  const loadmanager = role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE ? false : true

  const loadorg = role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE ? true : false

  const [department, setDepartment] = useState<any[]>([])
  const [notification, setNotification] = useState<boolean>(false)
  const [stopTimer, setStopTimer] = useState<boolean>(true)
  const [manualtracking, setManualTracking] = useState<boolean>(false)
  const [liveStream, setLiveStream] = useState<boolean>(false)
  const [liveRecording, setLiveRecording] = useState<boolean>(false)
  const [managerOption, setManagerOption] = useState<[]>([])
  const [rolesOption, setRolesOption] = useState<[]>([])
  const [departmentOption, setDepartmentOption] = useState<[]>([])
  const [alignment, setAlignment] = React.useState('Default')
  const [disableTimePicker, setDisableTimePicker] = useState<boolean>(true)
  const [timePickerDefaultValue, setTimePickerDefaultValue] = useState<any>(
    dayjs(localStorage.getItem('orgCutOffTime')?.replaceAll('.', ':'), 'HH:mm:ss')
  )
  const [selectedTimeZone, setSelectedTimeZone] = useState<any[]>([])
  const [syncData, setSyncData] = useState<boolean>(true)

  const memberSchema = baseMemberSchema.shape({
    organizationId: Yup.mixed().when([], {
      is: () => loadorg,
      then: Yup.mixed().required('Please select Organization'),
      otherwise: Yup.mixed().notRequired(),
    }),
    roleId: Yup.mixed().required('Role is required'),
  })

  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const initialValues = {
    managerId: '',
    organizationId: localStorage.getItem('org_Id'),
    email: '',
    roleId: '',
    fullName: '',
    address: '',
    contactNo: '',
    isScreenShotEnable: false,
    createdDate: dayjs().format('DD-MM-YYYY'),
    createdBy: localStorage.getItem('name'),
    baseLocation: '',
    departmentId: '',
    workingDays: [],
    isNotificationEnable: false,
    IsManualTrackingEnable: false,
    IsStopTimerEnable: true,
    userCutOffTime: localStorage.getItem('orgCutOffTime') || undefined,
    isLiveStreamEnable: false,
    isLiveRecordingEnable: false,
    empId: '',
    timeZoneId: localStorage.getItem('timezone'),
    isMonitoringEnable: true,
    isViewScreenShotEnable: false,
    // userScreenshotBlurEffect: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: memberSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      values.isScreenShotEnable = checked
      values.isNotificationEnable = notification
      values.IsStopTimerEnable = stopTimer
      values.IsManualTrackingEnable = manualtracking
      values.isLiveStreamEnable = liveStream
      values.isLiveRecordingEnable = liveRecording
      values.userCutOffTime = values.userCutOffTime?.replaceAll(':', '.')
      //console.log(values)

      axios
        .post(`${API}/Registration/Register`, values)
        .then((res) => {
          AlertModal(
            res.data.message,
            '',
            res.data.success ? 'success' : 'warning',
            false,
            '#7066E0',
            'Ok'
          ).then(() => {
            if (res.data.success === false) {
              resetForm()
              navigate('/member')
            } else {
              resetForm()
              setLoading(false)
              navigate('/member')
            }
          })
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setSubmitting(false)
        })
      setDepartment([])
      setRoles([])
      setManager([])
      resetForm()
      setLoading(false)
    },
  })

  useEffect(() => {
    getAllUser()
    getRoles()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.roleName,
            value: item.roleId,
          }
        })

        const userRole = newList.find((item: any) => item.label === 'User')
        setRoles(userRole)
        formik.setFieldValue('roleId', userRole.value)
        setRolesOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })

    departmentInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.department,
            value: item.departmentId,
          }
        })
        setDepartmentOption(newList)
      })
      .catch((err) => {
        console.log(err)
      })

    const storedTimeZoneId = localStorage.getItem('timezone')

    getTimeZone()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.timeZonesName,
            value: item.id,
          }
        })
        const selectedTimeZoneValue = newList.find((item: any) => item.value === storedTimeZoneId)

        setSelectedTimeZone(selectedTimeZoneValue)
        setTimeZone(newList)
      })
      .catch((err) => console.log(err))

    axios
      .get(`${API}/OrganizationAppConfiguration/GetOrganizationAppConfigById`, {
        params: {
          Org_ID: localStorage.getItem('org_Id'),
        },
      })
      .then((res) => {
        if (localStorage.getItem('orgCutOffTime') == null) {
          localStorage.setItem('orgCutOffTime', res.data.cutOffTime?.replaceAll(':', '.'))
          formik.setFieldValue('userCutOffTime', res.data.cutOffTime?.replaceAll(':', '.'))
          setTimePickerDefaultValue(dayjs(res.data.cutOffTime?.replaceAll('.', ':'), 'HH:mm:ss'))
        }
        formik.setFieldValue('workingDays', res.data.workingDays)
      })
  }, [])

  const getAllUser = async () => {
    try {
      const org = localStorage.getItem('org_Id')
      const userList = await GetActiveInactiveUser(org)

      setManagerOption(userList)
    } catch (error) {
      ErrorModal()
    }
  }

  useEffect(() => {
    if (role === process.env.REACT_APP_SYSTEM_FOURTH_ROLE)
      companyInfo()
        .then((res) => {
          setCompanies(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
  }, [])

  const status = () => {
    return `/member`
  }

  const handleToggle = (event: any) => {
    if (event.target.value === 'Default') {
      setAlignment(event.target.value)
      setDisableTimePicker(true)
      formik.setFieldValue(
        'userCutOffTime',
        localStorage.getItem('orgCutOffTime')?.replaceAll(':', '.')
      )
      setTimePickerDefaultValue(
        dayjs(localStorage.getItem('orgCutOffTime')?.replaceAll('.', ':'), 'HH:mm:ss')
      )
    } else if (event.target.value === 'Custom') {
      setAlignment(event.target.value)
      setDisableTimePicker(false)
    }
  }

  const handleCheckboxChange = (day: string) => {
    const updatedWorkingDays: string[] = [...formik.values.workingDays]
    const isChecked =
      formik.values?.workingDays?.length > 0 ? updatedWorkingDays.includes(day) : false
    if (isChecked) {
      const index = updatedWorkingDays.indexOf(day)
      updatedWorkingDays.splice(index, 1)
    } else {
      updatedWorkingDays.push(day)
    }

    formik.setFieldValue('workingDays', updatedWorkingDays)
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0' role='banner'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Member Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Name</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border '
                  placeholder='Name'
                  {...formik.getFieldProps('fullName')}
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.fullName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-border '
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Employee Id</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  autoComplete='new-password'
                  type='text'
                  className='form-control form-control-border '
                  placeholder='Employee Id'
                  {...formik.getFieldProps('empId')}
                />
                {formik.touched.empId && formik.errors.empId && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.empId}</div>
                  </div>
                )}
              </div>
            </div>

            {loadorg && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Organization
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('organizationId')}
                  >
                    <option value='' hidden>
                      Select the Organization
                    </option>
                    {companies.map((lead) => (
                      <option key={lead.org_Id} value={lead.org_Id}>
                        {lead.organizationName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.organizationId && formik.errors.organizationId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.organizationId}</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Department</label>
              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Department'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={departmentOption}
                    value={department}
                    onBlur={formik.handleBlur}
                    placeholder='Select Department'
                    onChange={(item: any) => {
                      setDepartment(item)
                      formik.setFieldValue('departmentId', item !== null ? item.value : '')
                    }}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                <div>
                  {formik.touched.departmentId && formik.errors.departmentId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.departmentId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {loadmanager && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Manager</label>
                <div className='col-lg-8 fv-row'>
                  <div
                    className='min-w-200px'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Search manager'
                  >
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      options={managerOption}
                      value={manager}
                      placeholder='Select manager'
                      onChange={(item: any) => {
                        setManager(item)
                        formik.setFieldValue('managerId', item !== null ? item.value : '')
                      }}
                      isClearable={true}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  </div>
                </div>
                {formik.touched.managerId && formik.errors.managerId && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.managerId}</div>
                  </div>
                )}
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>

              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Role'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={rolesOption}
                    value={roles}
                    onBlur={formik.handleBlur}
                    placeholder='Select Role'
                    onChange={(item: any) => {
                      setRoles(item)
                      formik.setFieldValue('roleId', item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                <div>
                  {formik.touched.roleId && formik.errors.roleId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.roleId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className=''>Base Location City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border '
                  placeholder='Base Location'
                  {...formik.getFieldProps('baseLocation')}
                />
                {formik.touched.baseLocation && formik.errors.baseLocation && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.baseLocation}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Time Zone</label>

              <div className='col-lg-8 fv-row'>
                <div
                  className='min-w-200px'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Role'
                >
                  <Select
                    className='selectDropdown'
                    components={makeAnimated()}
                    options={timeZone}
                    value={selectedTimeZone}
                    onBlur={formik.handleBlur}
                    placeholder='Select Time Zone'
                    onChange={(item: any) => {
                      setSelectedTimeZone(item)
                      formik.setFieldValue('timeZoneId', item.value)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnScroll={true}
                  />
                </div>
                <div>
                  {formik.touched.timeZoneId && formik.errors.timeZoneId && (
                    <div className='fv-plugins-message-container'>
                      <div className='text-danger mt-2'>{formik.errors.timeZoneId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Contact No</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-border '
                  placeholder='Contact No'
                  {...formik.getFieldProps('contactNo')}
                />
                {formik.touched.contactNo && formik.errors.contactNo && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.contactNo}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-border '
                  placeholder='Address'
                  {...formik.getFieldProps('address')}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Cut Off Time (hh:mm:ss)
              </label>
              <div className='col-lg-8 fv-row d-flex align-items-center'>
                <div style={{marginRight: '1rem'}}>
                  <ToggleButtonGroup
                    color='primary'
                    value={alignment}
                    onChange={handleToggle}
                    aria-label='Platform'
                  >
                    <ToggleButton value='Default'>Default</ToggleButton>
                    <ToggleButton value='Custom'>Custom</ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      disabled={disableTimePicker}
                      value={timePickerDefaultValue}
                      sx={{
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0)',
                        },
                      }}
                      timeSteps={{hours: 1, minutes: 1, seconds: 1}}
                      format='HH:mm:ss'
                      ampm={false}
                      views={['hours', 'minutes', 'seconds']}
                      onChange={(value: any) => {
                        formik.setFieldValue(
                          'userCutOffTime',
                          dayjs(value).format('HH:mm:ss').replaceAll(':', '.')
                        )
                        setTimePickerDefaultValue(value)
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                Working Days Per Week(Days)
              </label>

              <div className='col-lg-8 fv-row'>
                <div
                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
                >
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Mon'
                      id='flexCheckDefault'
                      checked={formik.values.workingDays?.some((day) => day === 'Mon') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Mon</label>
                  </div>

                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Tue'
                      id='flexCheckChecked'
                      checked={formik.values.workingDays?.some((day) => day === 'Tue') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Tue</label>
                  </div>

                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Wed'
                      id='kt_check_indeterminate_1'
                      checked={formik.values.workingDays?.some((day) => day === 'Wed') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Wed</label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Thu'
                      id='kt_check_indeterminate_1'
                      checked={formik.values.workingDays?.some((day) => day === 'Thu') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Thu</label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Fri'
                      id='kt_check_indeterminate_1'
                      checked={formik.values.workingDays?.some((day) => day === 'Fri') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Fri</label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Sat'
                      id='kt_check_indeterminate_1'
                      checked={formik.values.workingDays?.some((day) => day === 'Sat') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Sat</label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='Sun'
                      id='kt_check_indeterminate_1'
                      checked={formik.values.workingDays?.some((day) => day === 'Sun') || false}
                      onChange={(e) => handleCheckboxChange(e.target.value)}
                    />
                    <label className='form-check-label'>Sun</label>
                  </div>
                </div>
                {formik.touched.workingDays && formik.errors.workingDays && (
                  <div className='fv-plugins-message-container'>
                    <div className='text-danger mt-2'>{formik.errors.workingDays}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Monitoring</label>
              <div className='col-lg-8 fv-row'>
                <Switch
                  onChange={() => {
                    formik.setFieldValue('isMonitoringEnable', !formik.values.isMonitoringEnable)
                  }}
                  checked={formik.values.isMonitoringEnable}
                />
              </div>
            </div>

            {loadtoggle && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Capture Screenshot</label>
                <div className='col-lg-8 fv-row'>
                  <Switch
                    onChange={() => {
                      checked ? setChecked(false) : setChecked(true)
                    }}
                    checked={checked}
                  />
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>View Screenshot</label>
              <div className='col-lg-8 fv-row'>
                <Switch
                  onChange={() => {
                    formik.setFieldValue(
                      'isViewScreenShotEnable',
                      !formik.values.isViewScreenShotEnable
                    )
                  }}
                  checked={formik.values.isViewScreenShotEnable}
                />
              </div>
            </div>

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Screenshot Blur Effect</label>
              <div className='col-lg-8 fv-row'>
                <RangeSlider
                  value={Number(formik.values.userScreenshotBlurEffect)}
                  onChange={(e: any) => formik.setFieldValue('userScreenshotBlurEffect', String(e))}
                  min={0}
                  max={3}
                  step={1}
                  labels={{
                    0: '0',
                    [formik.values.userScreenshotBlurEffect]:
                      formik.values.userScreenshotBlurEffect,
                    3: '3',
                  }}
                ></RangeSlider>
              </div>
            </div> */}

            {ConfigDetails.isEnableLiveStreaming && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Stream</label>
                <div className='col-lg-8 fv-row'>
                  <Switch
                    onChange={() => {
                      liveStream ? setLiveStream(false) : setLiveStream(true)
                    }}
                    checked={liveStream}
                  />
                </div>
              </div>
            )}

            {ConfigDetails.isEnableLiveRecording && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Live Recording</label>
                <div className='col-lg-8 fv-row'>
                  <Switch
                    onChange={() => {
                      liveRecording ? setLiveRecording(false) : setLiveRecording(true)
                    }}
                    checked={liveRecording}
                  />
                </div>
              </div>
            )}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Notification</label>
              <div className='col-lg-8 fv-row'>
                <Switch
                  onChange={() => {
                    notification ? setNotification(false) : setNotification(true)
                  }}
                  checked={notification}
                />
                {/* <MaterialSwitch onChange={handleToggle} checked={checked} /> */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Stop Timer Enable</label>
              <div className='col-lg-8 fv-row'>
                <Switch
                  onChange={() => {
                    stopTimer ? setStopTimer(false) : setStopTimer(true)
                  }}
                  checked={stopTimer}
                />
              </div>
            </div>

            {ConfigDetails.isEnableManualTracking && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Private Mode</label>
                <div className='col-lg-8 fv-row'>
                  <Switch
                    onChange={() => {
                      manualtracking ? setManualTracking(false) : setManualTracking(true)
                    }}
                    checked={manualtracking}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='button' className='btn btn-secondary' onClick={() => navigate(status())}>
              Cancel
            </button>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Add Member</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmployeeDetails
