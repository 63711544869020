/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {memberInfoByOrg, departmentInfoByOrg} from '../../../modules/auth/redux/AuthCRUD'
import {KTSVG} from '../../../../_metronic/helpers'

import axios from 'axios'
import image2 from '../../../../images/60111.jpg'
import dayjs from 'dayjs'
import {
  Pagination,
  MemberAndPlanSearchBar,
  Loader,
  RowsPerPage,
  AlertModal,
  deleteHandler,
  guidedTour,
} from '../../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {getMembersByDepartment, UnlockUserDevice} from '../../../services/memberInfo.service'
import swal from 'sweetalert2'
import {driver} from 'driver.js'
import 'driver.js/dist/driver.css'
const API_URL = process.env.REACT_APP_API_URL

const Mem_admin: React.FC = () => {
  const [members, setMembers] = useState<any[]>([])
  const [currentData, setCurrentData] = useState<any[]>([])
  const [filteredMembers, setFilteredMembers] = useState<any[]>([])
  const [users, _] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [rowPerPage, setRowPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [forcePage, setForcePage] = useState<any>(null)
  const [tour, setTour] = useState(1)
  const [sortingOrders, setSortingOrders] = useState<{[key: string]: 'asc' | 'desc'}>({})
  const [selectedDepartment, setSelectedDepartment] = useState<any>({
    label: 'All Department',
    value: 'All',
  })
  const [department, setDepartment] = useState<any>([])
  const [loader, setLoader] = useState(false)

  let itemsPerPage: number = rowPerPage
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    let updatedFilteredMembers = members.filter(
      (member) =>
        member.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.email.toLowerCase().includes(searchQuery.toLowerCase())
    )

    const newEndOffset = itemOffset + itemsPerPage
    const item = updatedFilteredMembers.slice(itemOffset, newEndOffset)
    const count = Math.ceil(updatedFilteredMembers.length / itemsPerPage)
    setFilteredMembers(updatedFilteredMembers)
    setCurrentData(item)
    setPageCount(count)
  }, [members, searchQuery, itemOffset, itemsPerPage])

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % members.length
    setItemOffset(newOffset)
  }

  const uploadMembers = async (e: any) => {
    setLoading(true)
    const orgId: any = localStorage.getItem('org_Id')

    const form = new FormData()

    form.append('UserRequestFile', e.target.files[0])

    form.append('OrganizationId', orgId)
    return axios
      .post(`${API_URL}/Registration/BulkUserUpload`, form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-type': 'multipart/form-data',
        },
      })
      .then((res: any) => {
        AlertModal(
          res.data.message,
          '',
          res.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        )

        e.target.value = null
        setLoading(false)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const getmember = () => {
    memberInfoByOrg()
      .then((res) => {
        setMembers(res.data)
        setLoader(false)
        setSpinner(false)
      })
      .catch((err) => {
        setLoader(false)
        setSpinner(false)
        console.log(err)
      })
  }

  useEffect(() => {
    setSpinner(true)
    getmember()
  }, [])

  const InactiveHandler = (Props: any) => {
    let body = {
      userId: Props,
      isActive: false,
      modifiedDate: dayjs().format('DD-MM-YYYY'),
      modifiedBy: localStorage.getItem('name'),
    }

    AlertModal(
      'Are you sure?',
      "You won't be able to revert this!",
      'warning',
      true,
      '#3085d6',
      'Yes, inactive it!'
    ).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .patch(`${API_URL}/Registration/DeleteUser`, body)
          .then((res) => {
            getmember()
            AlertModal(
              res.data.message,
              '',
              res.data.success ? 'success' : 'warning',
              false,
              '#7066E0',
              'Ok'
            )
          })
          .catch((err) => {
            console.log(err.data.message)
          })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        AlertModal('Member not Inactived', '', 'error', false, '#7066E0', 'Ok')
      }
    })
  }

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
    setItemOffset(0)
  }

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = '/media/sample/Sample.xlsx'
    link.download = 'sample.xlsx'
    link.dispatchEvent(new MouseEvent('click'))
  }

  useEffect(() => {
    const getDepartments = async () => {
      const departments = await departmentInfoByOrg()

      const newList = departments.data.map((item: any) => {
        return {
          label: item.department,
          value: item.departmentId,
        }
      })

      newList.unshift({
        label: 'All Department',
        value: 'All',
      })

      setDepartment(newList)
    }

    getDepartments()
  }, [])

  const handleDepartmentChange = async (departmentData: any) => {
    setSelectedDepartment(departmentData)
    if (departmentData.value === 'All') {
      setLoader(true)
      getmember()
    } else {
      setLoader(true)

      const allMembers = await getMembersByDepartment(departmentData.value)

      setMembers(allMembers.data)
      setLoader(false)
    }
  }

  const handleSorting = (sortingType: string) => {
    const currentSortingOrder = sortingOrders[sortingType] || 'asc'

    const newSortingOrder = currentSortingOrder === 'asc' ? 'desc' : 'asc'

    let sortedItems = [...currentData]

    switch (sortingType) {
      case 'Name':
        sortedItems.sort((a, b) => compareStrings(a.fullName, b.fullName, newSortingOrder))
        break
      case 'Manager':
        sortedItems.sort((a, b) => compareStrings(a.managerName, b.managerName, newSortingOrder))
        break
      case 'EmpId':
        sortedItems.sort((a, b) => compareStrings(a.empId, b.empId, newSortingOrder))
        break
      case 'Email':
        sortedItems.sort((a, b) => compareStrings(a.email, b.email, newSortingOrder))
        break

      default:
        sortedItems.sort((a, b) => compareStrings(a.fullName, b.fullName, newSortingOrder))
        break
    }

    setCurrentData(sortedItems)

    setSortingOrders({
      ...sortingOrders,
      [sortingType]: newSortingOrder,
    })
  }

  const compareStrings = (a: string, b: string, order: string) => {
    const aValue = a || ''
    const bValue = b || ''

    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
  }

  const UnblockUserMachine = async (userId: string) => {
    try {
      let unblockMachine = await UnlockUserDevice(userId)
      if (unblockMachine.data.success === true) {
        AlertModal(
          unblockMachine.data.message,
          '',
          unblockMachine.data.success ? 'success' : 'warning',
          false,
          '#7066E0',
          'Ok'
        ).then(() => {
          getmember()
        })
      } else {
        AlertModal('Something went wrong.', '', 'warning', false, '#7066E0', 'Ok')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('tour')) {
      localStorage.removeItem('tour')
      guidedTour()
    }
  }, [])

  return (
    <div className='card mb-5 mb-xl-8'>
      {/* begin::Header */}

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Member</span>
          <span className='text-muted mt-1 fw-bold fs-7'></span>
        </h3>

        <div className='d-flex'>
          <div className='d-flex'>
            <span
              className='d-flex align-items-center'
              style={{fontWeight: '500', fontStyle: 'bold', fontSize: '15px'}}
            >
              Department :
            </span>

            <div
              className='card-toolbar mx-3'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Select
                components={makeAnimated()}
                value={selectedDepartment}
                options={department}
                placeholder='Select Department'
                onChange={(item: any) => {
                  handleDepartmentChange(item)
                  setForcePage(null)
                  setItemOffset(0)
                  setTimeout(() => {
                    setForcePage(0)
                  }, 100)
                }}
                isClearable={false}
                isSearchable={true}
                closeMenuOnScroll={true}
              />
            </div>
          </div>

          {spinner
            ? ''
            : MemberAndPlanSearchBar(
                'Search members by name or email',
                'Search by Name or Email',
                handleSearchChange
              )}

          <Tippy placement='top' content='Click to download a sample  file'>
            <div
              className='card-toolbar '
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Link
                to='#'
                className='me-3 tourDownloadSamle d-flex align-items-center'
                onClick={() => handleDownload()}
              >
                <span style={{flexGrow: 1, textAlign: 'center'}}>*</span>
                Download Sample file
              </Link>
            </div>
          </Tippy>

          <Tippy placement='top' content='Click to upload'>
            <div
              className='card-toolbar '
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <label className='btn btn-sm btn-primary me-3 tourUpload'>
                <input
                  disabled={loading}
                  className='btn btn-sm btn-primary me-3'
                  type='file'
                  accept='.xlsx'
                  style={{display: 'none', cursor: 'pointer'}}
                  onChange={(e: any) => uploadMembers(e)}
                ></input>

                {!loading && (
                  <>
                    <KTSVG path='/media/icons/duotune/art/upload.svg' className='svg-icon-3' />
                    <span className='indicator-label'>Upload</span>
                  </>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </label>
            </div>
          </Tippy>

          <Tippy placement='top' content='Click to add a user'>
            <div
              className='card-toolbar tourAddMember'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              <Link to='/member/addmember' className='btn btn-sm btn-primary me-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                New Member
              </Link>
            </div>
          </Tippy>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {spinner || loader ? (
        Loader('0px')
      ) : (
        <div className='card-body py-3 mb-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder'>
                  <th></th>

                  <th className='min-w-125px'>
                    <div className='d-flex mx-20'>
                      Name
                      <div className='mx-3'>
                        <Tippy placement='top' content='Sort by Name'>
                          <span className='cursor-pointer' onClick={() => handleSorting('Name')}>
                            <KTSVG
                              path='/media/icons/duotune/art/sort.svg'
                              className='svg-icon-3'
                            />
                          </span>
                        </Tippy>
                      </div>
                    </div>
                  </th>

                  <th className='min-w-125px'>
                    <div className='d-flex'>
                      Manager
                      <div className='mx-3'>
                        <Tippy placement='top' content='Sort by Manager'>
                          <span className='cursor-pointer' onClick={() => handleSorting('Manager')}>
                            <KTSVG
                              path='/media/icons/duotune/art/sort.svg'
                              className='svg-icon-3'
                            />
                          </span>
                        </Tippy>
                      </div>
                    </div>
                  </th>

                  <th className='min-w-125px text-center'>
                    <div className='d-flex'>
                      Employee Id
                      <div className='mx-3'>
                        <Tippy placement='top' content='Sort by Employee Id'>
                          <span className='cursor-pointer' onClick={() => handleSorting('EmpId')}>
                            <KTSVG
                              path='/media/icons/duotune/art/sort.svg'
                              className='svg-icon-3'
                            />
                          </span>
                        </Tippy>
                      </div>
                    </div>
                  </th>

                  <th className='min-w-150px'>
                    <div className='d-flex'>
                      Email
                      <div className='mx-3'>
                        <Tippy placement='top' content='Sort by Email'>
                          <span className='cursor-pointer' onClick={() => handleSorting('Email')}>
                            <KTSVG
                              path='/media/icons/duotune/art/sort.svg'
                              className='svg-icon-3'
                            />
                          </span>
                        </Tippy>
                      </div>
                    </div>
                  </th>

                  <th className='min-w-125px text-center'>Activation Date</th>
                  <th className='min-w-100px'>App Version</th>
                  <th className='min-w-100px text-center'>Actions</th>
                  <th></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {currentData.map((names) => (
                  <tr key={names.userId}>
                    <td></td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          {/* <img src={toAbsoluteUrl(`${API_URL}/images/${names.images}`)} alt={names.firstName} /> */}
                          <img src={image2} />
                        </div>

                        <div className='d-flex justify-content-start flex-column fs-6'>
                          {names.fullName}
                          <span className='text-muted fw-bold d-block fs-7'>
                            <span>{names.roleName}</span>
                            <span className='mx-7'> {names.department}</span>
                          </span>
                          <span className='text-muted fw-bold d-block fs-7'>{names.contactNo}</span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className='text-dark  d-block fs-6 '>{names.managerName}</span>
                    </td>

                    <td>
                      <span className='text-dark text-center d-block fs-6'>{names.empId}</span>
                    </td>

                    <td>
                      <span className='text-dark  d-block fs-6'>{names.email}</span>
                    </td>

                    <td>
                      <span className='text-dark d-block fs-6 mx-3 text-center'>
                        {names.activationDate
                          ? dayjs(names.activationDate).format('DD/MM/YYYY')
                          : '-'}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark d-block fs-6 text-center'>{names.appVersion}</span>
                    </td>

                    <td>
                      <div className='d-flex justify-content-around flex-shrink-0'>
                        <Tippy placement='top' content='Edit Member'>
                          <Link
                            to='/editEmp'
                            state={users}
                            onClick={() => {
                              localStorage.setItem('editUser', names.userId)
                              localStorage.setItem('editIsActive', 'Active')
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                        </Tippy>

                        <Tippy placement='top' content='Inactive Member'>
                          <a
                            onClick={() => InactiveHandler(names.userId)}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                              names.userId === localStorage.getItem('userId') ? 'disabled' : ''
                            }`}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/user-inactive.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tippy>

                        <Tippy placement='top' content='Delete Member'>
                          <a
                            onClick={() =>
                              deleteHandler(
                                names.userId,
                                'active',
                                setSpinner,
                                setItemOffset,
                                // setForcePage,
                                getmember
                              )
                            }
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm ${
                              names.userId === localStorage.getItem('userId') ? 'disabled' : ''
                            }`}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </Tippy>

                        <Tippy placement='top' content='Unblock User Machine'>
                          <div
                            onClick={() => {
                              UnblockUserMachine(names.userId)
                            }}
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ${
                              names.systemIdentifier === '' ? 'disabled' : ''
                            }`}
                          >
                            <KTSVG
                              path={
                                names.systemIdentifier === ''
                                  ? '/media/icons/duotune/art/unlock.svg'
                                  : '/media/icons/duotune/art/lock.svg'
                              }
                              className='svg-icon-3'
                            />
                          </div>
                        </Tippy>
                      </div>
                    </td>

                    <td></td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
          {members.length > 10 && (
            <div className='d-flex align-items-center justify-content-center position-relative mb-3'>
              <div style={{left: '2rem', position: 'absolute', top: '0'}}>
                {RowsPerPage(
                  setForcePage,
                  setRowPerPage,
                  setItemOffset,
                  rowPerPage,
                  members.length,
                  '0px'
                )}
              </div>
              {filteredMembers.length > rowPerPage &&
                Pagination(handlePageClick, pageCount, forcePage)}
            </div>
          )}
          <span
            className='d-flex justify-content-center'
            style={{width: '100%', fontWeight: '600'}}
          >
            Showing {currentData.length} members out of {members.length}
          </span>

          {/* end::Table */}

          {/* end::Table container */}
        </div>
      )}
      <style>
        {`
                        
                        .custom-select-class{
                          text-align: center;
                          width: 8rem;
                        }

                        .select-custom-header{
                          margin-right: 1rem;
                          line-height: 3rem;
                        }

                        .card-toolbar > .css-b62m3t-container > div {
                          width:13rem !important;
                        }
                        

                        

                        
                    `}
      </style>

      {/* begin::Body */}
    </div>
  )
}

export {Mem_admin}
