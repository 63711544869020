import dayjs from 'dayjs'
import React, {useState, useEffect} from 'react'
import '../Timeline/TimelineCSS.css'
import axios, {CancelTokenSource} from 'axios'
import Chart from 'react-apexcharts'
import Lightbox from 'react-image-lightbox'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {member, memberInfoByOrg} from '../../modules/auth/redux/AuthCRUD'
import {
  Loader,
  SingleDatePickerComponent,
  loadAssigeeAndMember,
} from '../../CommonFunctions/CommonFunction'
import {convertSecIntoHoursMinSec} from '../Common_Function/Function'
import {GetAssignee} from '../../services/GetAllAssinee.services'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth/redux/UserInfoRedux'

let cancelTokenSource: CancelTokenSource | null = null
const API_URL = process.env.REACT_APP_API_URL

const Timelines = () => {
  const role = localStorage.getItem('role')
  const [startDate, setStartDate] = useState(new Date())
  const [posts, setPosts] = useState([])
  const [members, setMembers] = useState<any[]>([])
  const [time, setTime] = useState<any>()
  const [list, setList] = useState<any[]>([])
  const [screenshots, setScreenShots] = useState<any>([])
  const [updatedImages, setUpdatedImages] = useState<any>([])
  const [imgIndex, setImgIndex] = useState(0)
  const [memberOption, setMemberOption] = useState<any>([])
  const [rawMembers, setRawMembers] = useState<any>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [startIndex, _] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [colorOfSelected, setColorOfSelected] = useState<any>('')
  const [screenloader, setScreenLoader] = useState<any>(false)
  const [stateShowData, setStateShowData] = useState<any>(false)
  const [userEmail, setUserEmail] = useState<any>('')
  const [userTimeZone, setUserTimeZone] = useState<string>('')
  const dispatch = useDispatch()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const [optionss, setOptionss] = useState<any>({
    xaxis: {
      categories: [],
    },
  })
  const [seriess, setSeriess] = useState<any>([
    {
      data: [],
    },
  ])

  const newOptions = (data: any) => {
    const colors = [
      '#33b2df',
      '#546E7A',
      '#d4526e',
      '#13d8aa',
      '#A5978B',
      '#2b908f',
      '#f9a3a4',
      '#90ee7e',
      '#f48024',
      '#69d2e7',
    ]
    setOptionss({
      chart: {
        id: 'basic-bar',
        type: 'bar',
        height: 'auto',
        width: '100%',
        // events: {
        //     click: function (chart: any, w: any, e: any) {
        //          console.log(chart, w, e)
        //     }
        // }
      },
      colors: colors,
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: 'none',
          },
        },
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#fff'],
        },
        formatter: function (val: any, opt: any) {
          //console.log(val)
          return convertSecIntoHoursMinSec(val)
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: data,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: true,
        },
        y: {
          title: {
            formatter: function (seriesName: any) {
              return 'Time : '
            },
          },
          formatter: function (val: any) {
            return dayjs().startOf('day').second(val).format('HH:mm:ss')
          },
        },
      },
    })
  }

  const newSeries = (data: any) =>
    setSeriess([
      {
        data,
      },
    ])

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    // autoplay: false,
    // autoplaySpeed: 3000,
    // pauseOnHover: false,
    // swipeToSlide: false,
    // centerMode: false,
    // afterChange: () => {

    // },
  }

  useEffect(() => {
    const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    if (!isGroupAdmin && !ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }

    if (ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: ConfigDetails?.selectedUserName,
        value: ConfigDetails?.selectedUserId,
      })
    }
    if (
      role === process.env.REACT_APP_SYSTEM_SECOND_ROLE ||
      role === process.env.REACT_APP_SYSTEM_THIRD_ROLE
    ) {
      GetAssignee()
        .then((res: any) => {
          setRawMembers(res.data)
          const newList = res.data.map((item: any) => {
            return {
              label: item.assigneeName,
              value: item.assigneeId,
            }
          })

          setMemberOption(newList)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    if (
      role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
      role === process.env.REACT_APP_SYSTEM_FIFTH_ROLE
    ) {
      getAllMembers()
    }
  }, [])

  useEffect(() => {
    if (rawMembers.length !== 0) {
      if (ConfigDetails.selectedUserId) {
        setEmailOfUser(ConfigDetails.selectedUserId).then((email) => {
          fetchDailyReport(ConfigDetails.selectedUserId)
          fetchScreenShots(ConfigDetails.selectedUserId, '', email)
        })
      } else {
        setUserEmail(localStorage.getItem('Email'))
        fetchDailyReport(ConfigDetails.userId)
        fetchScreenShots(ConfigDetails.userId, '', localStorage.getItem('Email'))
      }
    } else if (ConfigDetails.role === process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
      setUserEmail(localStorage.getItem('Email'))
      fetchDailyReport(ConfigDetails.userId)
      fetchScreenShots(ConfigDetails.userId, '', localStorage.getItem('Email'))
    }
  }, [rawMembers])

  const earlyStageCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }
    setPosts([])
    setScreenLoader(true)
    setList([])
    setUpdatedImages([])
    setTime('')
    setColorOfSelected('')
    setStateShowData(false)
    newSeries([])
  }

  const SendDataOfUser = async (item: any) => {
    console.log(item)

    earlyStageCall()
    let email
    await setEmailOfUser(item).then((res) => (email = res))
    //console.log(email)
    fetchDailyReport(item, dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'))
    fetchScreenShots(item, dayjs(startDate).format('YYYY-MM-DDT00:00:00[Z]'), email)
  }

  const SendDataOfDate = (date: any) => {
    earlyStageCall()

    fetchDailyReport(optionSelectedMember.value, dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'))
    fetchScreenShots(optionSelectedMember.value, dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'))
  }

  const fetchDailyReport = (userId?: any, date?: any) => {
    const body = {
      userId: userId ? userId : localStorage.getItem('userId'),
      date: date ? date : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
      organizationId: localStorage.getItem('org_Id'),
    }
    cancelTokenSource = axios.CancelToken.source()
    axios
      .post(`${API_URL}/TimeLineReport/TimeLineDailyReport`, body, {
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setUserTimeZone(res.data.timeZone)
        setPosts(res.data.timeLineDailyReport)
        if (res.data.length > 0) {
          setStateShowData(true)
        }
        setScreenLoader(false)
      })
      .catch((err) => {
        console.log(err)
        setScreenLoader(false)
      })
  }

  const fetchScreenShots = (userId?: any, reportDate?: any, email?: any) => {
    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(`${API_URL}/CloudStorageScreenshots/GetHourlyScreenShots`, {
        params: {
          UserId: userId ? userId : localStorage.getItem('userId'),
          Email: email ? email : userEmail,
          ReportDate: reportDate ? reportDate : dayjs().format('YYYY-MM-DDT00:00:00[Z]'),
          OrganizationId: localStorage.getItem('org_Id'),
        },
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setScreenShots(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const setEmailOfUser = async (userId: any) => {
    try {
      const user = rawMembers.filter((item: any) => item.userId == userId)
      setUserEmail(user[0].email)
      return user[0].email
    } catch (error) {
      const user = rawMembers.filter((item: any) => item.assigneeId == userId)
      setUserEmail(user[0].assigneeEmail)
      return user[0].assigneeEmail
    }
    // console.log('screnrio')
    // if (ConfigDetails.role === process.env.REACT_APP_SYSTEM_THIRD_ROLE) {
    //   let email = localStorage.getItem('isGroupAdmin')
    //   setUserEmail(email)
    //   return email
    // } else {
    //   try {
    //     const user = rawMembers.filter((item: any) => item.userId == userId)
    //     setUserEmail(user[0].email)
    //     return user[0].email
    //   } catch (error) {
    //     const user = rawMembers.filter((item: any) => item.assigneeId == userId)
    //     setUserEmail(user[0].assigneeEmail)
    //     return user[0].assigneeEmail
    //   }
    // }
  }

  const getAllMembers = () => {
    memberInfoByOrg()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.fullName,
            value: item.userId,
          }
        })
        if (role !== process.env.REACT_APP_SYSTEM_SECOND_ROLE) setMemberOption(newList)
        setRawMembers(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getList = async (prop: any) => {
    setColorOfSelected(prop)
    let graphXaxis: any = []
    let graphYaxis: any = []

    const data: any = posts.filter((item: any) => {
      return item.range == prop
    })

    for (const value of data[0].activity) {
      graphXaxis.push(value.appName)
      graphYaxis.push(Number(value.processTotalTimeSeconds))
    }
    //console.log(graphYaxis)
    newOptions(graphXaxis)
    newSeries(graphYaxis)

    for (const listData of data) {
      setList(listData.activity)
      if (listData.activity.length > 0) {
        setStateShowData(false)
      }
    }
    await CallScreenShot(prop)
  }

  const CallScreenShot = async (prop: any) => {
    const data: any = screenshots.filter((item: any) => {
      return item.range == prop
    })
    let newArray: string[] = []
    if (data.length === 0) {
      setUpdatedImages([])
    } else {
      data[0].blobResponse.map((images: any) => {
        newArray.push(images)
      })
      await setUpdatedImages(newArray)
    }
  }

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    SendDataOfDate(dates)
  }

  return (
    <>
      <div className='card mb-5'>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Timeline</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>More than 400 new authors</span> */}
          </h3>

          <div className='d-flex align-items-center'>
            <label className='mx-5 fs-5 fw-bold'>
              <span className='fw-bolder'>TimeZone - </span>
              {userTimeZone}
            </label>
            {memberOption.length > 1 && (
              <div
                style={{width: '200px'}}
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Search Member'
              >
                <Select
                  components={makeAnimated()}
                  value={optionSelectedMember}
                  options={memberOption}
                  placeholder='Select Member'
                  onChange={(item: any) => {
                    setOptionSelectedMember(item)

                    dispatch(
                      userInfoActions.updateSelectedUser({
                        selectedUserName: item.label,
                        selectedUserId: item.value,
                      })
                    )

                    SendDataOfUser(item.value)
                  }}
                  isClearable={false}
                  isSearchable={true}
                  closeMenuOnScroll={true}
                />
              </div>
            )}

            <div className='d-flex'>
              {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex'>
        <div
          style={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '35%',
            background: '#c4cfe2',
            borderRadius: '10px',
            marginLeft: '33px',
            height: '460px',
          }}
        >
          {/* <h3 style={{ marginBottom: '20px' }}> Select Time Slot</h3> */}
          <div
            style={{
              overflowX: 'hidden',
              display: 'grid',
              paddingRight: '10px',
            }}
          >
            {posts?.map((item: any, index) => (
              <a
                key={index}
                className={`rounded p-3 hover-scale hoverColm mb-2 ${
                  colorOfSelected == item.range ? 'blackSelectedBackground' : ''
                }`}
                onClick={() => {
                  getList(item.range)
                  setTime(item.range)
                }}
                style={{cursor: 'pointer'}}
              >
                {item.range}
              </a>
            ))}

            {screenloader
              ? Loader('150px')
              : posts.length === 0 && <h2 className='borderNoRecordFound'>No Records Found</h2>}
          </div>
        </div>

        <div
          style={{
            padding: '25px 30px',
            display: 'flex',
            flexDirection: 'column',
            width: '57%',
            background: '#c4cfe2',
            borderRadius: '10px',
            marginLeft: '33px',
            height: '460px',
          }}
        >
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <h4> {time}</h4>
            <div className='card-toolbar'>
              <ul className='nav'>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm  btn-active btn-active-light-primary active fw-bolder px-4 me-1'
                    style={{padding: '5px', background: '#1fd4ec57'}}
                    data-bs-toggle='tab'
                    href='#kt_table_widget_6_tab_1'
                  >
                    List
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm  btn-active btn-active-light-primary fw-bolder px-4'
                    style={{padding: '5px', background: '#1fd4ec57'}}
                    data-bs-toggle='tab'
                    href='#kt_table_widget_6_tab_2'
                  >
                    Graph
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='tab-content' style={{overflowX: 'hidden'}}>
            <div className='tab-pane fade active show' id='kt_table_widget_6_tab_1'>
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed align-middle gs-0 gy-4 '>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder fs-6 c-B'>
                      <th></th>
                      <th className=' min-w-125px'>AppName</th>
                      <th className=' min-w-150px'>Url</th>
                      <th className=' min-w-125px text-center'>Time Elapsed</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {list.map((listMapping, index) => (
                      <tr key={index}>
                        <td></td>
                        <td>
                          <span className='fw-bold d-block fs-7'>{listMapping.appName}</span>
                        </td>
                        <td>
                          <span className='fw-bold d-block fs-7' style={{lineBreak: 'anywhere'}}>
                            {listMapping.url}{' '}
                          </span>
                        </td>
                        <td>
                          <span className='fw-bold d-block fs-7 text-center'>
                            {convertSecIntoHoursMinSec(listMapping.processTotalTimeSeconds)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {stateShowData ? <h2 className='borderNoRecordFound'>Select Time Slots</h2> : ''}
              </div>
            </div>
            <div className='tab-pane fade' id='kt_table_widget_6_tab_2'>
              {/* <div ref={chartRef} id='Timeline_chart' /> */}
              <Chart options={optionss} series={seriess} type='bar' />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: '20px'}}>
        {updatedImages.length > 0 && (
          <Slider {...settings}>
            {updatedImages.map((image: any, index: any) => (
              <div key={index}>
                <img
                  src={image.original}
                  style={{width: '13rem', cursor: 'pointer'}}
                  onClick={() => {
                    toggleModal()
                    setImgIndex(index + startIndex)
                  }}
                />
              </div>
            ))}
          </Slider>
        )}

        {modalIsOpen && (
          <Lightbox
            imageTitle={''}
            imageCaption={updatedImages[imgIndex].description}
            mainSrc={updatedImages[imgIndex].original}
            nextSrc={`${updatedImages[(imgIndex + 1) % updatedImages.length]}`}
            prevSrc={`${
              updatedImages[(imgIndex + updatedImages.length - 1) % updatedImages.length]
            }`}
            onCloseRequest={() => toggleModal()}
            onMovePrevRequest={() =>
              setImgIndex((imgIndex + updatedImages.length - 1) % updatedImages.length)
            }
            onMoveNextRequest={() => setImgIndex((imgIndex + 1) % updatedImages.length)}
            animationDisabled={false}
            imagePadding={60}
          />
        )}
      </div>
    </>
  )
}

export {Timelines}
