import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios, {CancelTokenSource} from 'axios'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'
import {
  Loader,
  SendDataOfUser,
  SingleDatePickerComponent,
  earlyStageCall,
  loadAssigeeAndMember,
} from '../../CommonFunctions/CommonFunction'
import Tippy from '@tippyjs/react'
import DetailDailySummary from './DetailDailySummary'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {userInfoActions} from '../../modules/auth/redux/UserInfoRedux'

const WeeklySummary: React.FC = () => {
  const API = process.env.REACT_APP_API_URL
  let cancelTokenSource: CancelTokenSource | null = null
  const role = localStorage.getItem('role')
  const [posts, setPosts] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [screenloader, setScreenLoader] = useState<boolean>(false)
  const [memberOption, setMemberOption] = useState<any[]>([])
  const [optionSelectedMember, setOptionSelectedMember] = useState<any>('')
  const [detailDailySummary, setDetailDailySummary] = useState<any[]>([])
  const dispatch = useDispatch()
  const ConfigDetails: any = useSelector<RootState>(({data}) => data, shallowEqual)

  const colorArray = [
    {
      colorCode: '#d9d9d9',
      statusName: 'Offline',
    },
    {
      colorCode: 'rgb(52, 168, 83)',
      statusName: 'Working',
    },
    {
      colorCode: 'rgb(157, 107, 80)',
      statusName: 'Stopped',
    },
    {
      colorCode: 'rgb(251, 188, 4)',
      statusName: 'Idle',
    },
  ]

  useEffect(() => {
    const isGroupAdmin = localStorage.getItem('isGroupAdmin')
    if (!isGroupAdmin && !ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: localStorage.getItem('name'),
        value: localStorage.getItem('userId'),
      })
    }

    if (ConfigDetails.selectedUserId) {
      setOptionSelectedMember({
        label: ConfigDetails?.selectedUserName,
        value: ConfigDetails?.selectedUserId,
      })
    }
    loadAssigeeAndMember(role, setMemberOption, setOptionSelectedMember, ConfigDetails)
  }, [])

  const SendDataOfDate = (date: any) => {
    earlyStageCall(cancelTokenSource, setPosts, setScreenLoader)
    fetchPosts(dayjs(date).format('YYYY-MM-DDT00:00:00[Z]'), optionSelectedMember.value)
  }

  const fetchPosts = (date: any, userId?: any) => {
    setScreenLoader(true)
    const body = {
      OrganizationId: localStorage.getItem('org_Id'),
      UserId: userId,
      ReportDate: date,
    }
    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(`${API}/SystemActivityReport/GetWeeklySystemActivityReport`, {
        params: body,
        cancelToken: cancelTokenSource?.token,
      })
      .then((res) => {
        setPosts(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setScreenLoader(false))
  }

  useEffect(() => {
    if (!ConfigDetails.selectedUserId) {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), localStorage.getItem('userId'))
    } else {
      fetchPosts(dayjs().format('YYYY-MM-DDT00:00:00[Z]'), ConfigDetails.selectedUserId)
    }
  }, [])

  const handleDateChange = (dates: any) => {
    setStartDate(dates)
    SendDataOfDate(dates)
  }

  const getWidth = (data: any) => {
    const sum = data?.reduce((acc: any, val: any) => {
      return (acc += val.spentTime)
    }, 0)
    const calculateWidth = (value: any) => {
      return (value / sum) * 100
    }

    const getColor = (status: string): string => {
      switch (status) {
        case 'Offline':
          return '#d9d9d9'
        case 'Working':
          return 'rgb(52, 168, 83)'
        case 'Stopped':
          return 'rgb(157, 107, 80)'
        case 'Idle':
          return 'rgb(251, 188, 4)'
        default:
          return ''
      }
    }

    return data?.map((item: any) => (
      <Tippy
        placement='top'
        content={
          dayjs(item.startTime).format('HH:mm:ss') + ' - ' + dayjs(item.endTime).format('HH:mm:ss')
        }
      >
        <div
          key={item.startTime}
          className='progress-bar'
          role='progressbar'
          style={{
            width: `${calculateWidth(item.spentTime)}%`,
            backgroundColor: getColor(item.userActivityStatus),
          }}
        ></div>
      </Tippy>
    ))
  }

  return (
    <>
      <div className={`card card-xxl-stretch mb-5 mt-8`}>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Weekly Activity Summary</span>
          </h3>

          <div className='d-flex align-items-center justify-content-end'>
            <div className='d-flex align-items-center'>
              <div>
                <div
                  style={{width: '200px'}}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Search Member'
                >
                  {role === process.env.REACT_APP_SYSTEM_THIRD_ROLE ? (
                    <>
                      {memberOption.length > 1 && (
                        <Select
                          className='selectDropdown'
                          components={makeAnimated()}
                          value={optionSelectedMember}
                          options={memberOption}
                          placeholder='select member'
                          onChange={(item: any) => {
                            dispatch(
                              userInfoActions.updateSelectedUser({
                                selectedUserName: item.label,
                                selectedUserId: item.value,
                              })
                            )
                            setOptionSelectedMember(item)
                            const userParams = {
                              userId: item.value,
                              cancelTokenSource,
                              setPosts,
                              setScreenLoader,
                              startDate,
                              fetchPosts,
                            }
                            SendDataOfUser(userParams)
                          }}
                          isClearable={false}
                          isSearchable={true}
                          closeMenuOnScroll={true}
                        />
                      )}
                    </>
                  ) : (
                    <Select
                      className='selectDropdown'
                      components={makeAnimated()}
                      value={optionSelectedMember}
                      options={memberOption}
                      placeholder='select member'
                      onChange={(item: any) => {
                        setOptionSelectedMember(item)
                        if (
                          role === process.env.REACT_APP_SYSTEM_FIRST_ROLE ||
                          role == process.env.REACT_APP_SYSTEM_FIFTH_ROLE ||
                          role == process.env.REACT_APP_SYSTEM_SECOND_ROLE
                        ) {
                          dispatch(
                            userInfoActions.updateSelectedUser({
                              selectedUserName: item.label,
                              selectedUserId: item.value,
                            })
                          )
                        }
                        const userParams = {
                          userId: item.value,
                          cancelTokenSource,
                          setPosts,
                          setScreenLoader,
                          startDate,
                          fetchPosts,
                        }
                        SendDataOfUser(userParams)
                      }}
                      isClearable={false}
                      isSearchable={true}
                      closeMenuOnScroll={true}
                    />
                  )}
                </div>
              </div>
              {SingleDatePickerComponent(startDate, handleDateChange, 'form-control ss_date')}
            </div>
          </div>
        </div>
      </div>

      <div className='card pb-9 px-10'>
        <div className='d-flex justify-content-center w-100 px-5 my-4'>
          <div className='d-flex p-2 border-primary border rounded'>
            {colorArray?.map((item) => (
              <div
                key={item.colorCode}
                className='d-flex justify-content-center align-items-center mx-5'
              >
                <span
                  className='d-flex justify-content-center align-items-center'
                  style={{
                    width: '22px',
                    height: '20px',
                    borderRadius: '68px',
                    background: `${item.colorCode}`,
                    textShadow: '2px 3px 2px black',
                  }}
                ></span>
                <span className='d-flex align-items-center' style={{marginLeft: '7px'}}>
                  {item.statusName}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4' style={{border: '1px solid '}}>
            <thead>
              <tr
                className='fw-bolder'
                style={{background: '#728FCE', fontSize: '15px', borderBottom: '1px solid'}}
              >
                <th className='min-w-75px text-center'>Date</th>
                <th className='min-w-350px text-center'>Summary</th>
                <th className='min-w-75px text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {!screenloader &&
                posts.length > 0 &&
                posts.map((data: any) => (
                  <tr key={data.date} style={{fontSize: '12px'}}>
                    <td>
                      <span className='text-dark d-flex fs-6 justify-content-center'>
                        {dayjs(data.date).format('DD/MM/YYYY')}
                      </span>
                    </td>

                    <td>
                      <div className='d-flex flex-column w-100 my-2'>
                        <div className='progress h-20px w-100' style={{border: '1px solid black'}}>
                          {getWidth(data.details)}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex justify-content-evenly flex-shrink-0'>
                        {data.details.length > 0 && (
                          <button
                            onClick={() => setDetailDailySummary(data.details)}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_DetailDailySummary'
                            className='btn btn-sm btn-primary'
                          >
                            View
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {screenloader && Loader('0')}
        </div>
      </div>

      <DetailDailySummary data={detailDailySummary} />
    </>
  )
}

export default WeeklySummary
