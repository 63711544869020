import React, {useState, useEffect} from 'react'
import axios, {CancelTokenSource} from 'axios'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {
  MultiDateTimePicker,
  SingleDatePickerComponent,
  Loader,
} from '../../../CommonFunctions/CommonFunction'
import dayjs from 'dayjs'
import {ProChart} from '../../Web&App/ProChart'
import '../../../../css/charts.css'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {RootState} from '../../../../setup'
import {userInfoActions} from '../../../modules/auth'
import {utils as XLSXUtils, writeFile} from 'xlsx'
import {GetAssignee} from '../../../services/GetAllAssinee.services'

const API = process.env.REACT_APP_API_URL

const API_URL = process.env.REACT_APP_API_URL
let cancelTokenSource: CancelTokenSource | null = null

const ProductivitysManager: React.FC = () => {
  const SelectedUser: any = useSelector<RootState>(({data}) => data, shallowEqual)
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState<any>(null)
  const [members, setMembers] = useState<any[]>([])
  const [records, setRecords] = useState<any[]>([])
  const [userId, setUserId] = useState('')
  const [role, setRole] = useState('manager')
  const [spinner, setSpinner] = useState(false)
  const [dateError, setDateError] = useState(false)

  //get the data between a range
  const dateRange = (start: any, end?: any, id?: any) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    setSpinner(true)
    if (id === 'allMember') {
      if (!start && !end) {
        setSpinner(false)
        return
      }

      if (!end) {
        end = start
      }

      let OrganizationId = localStorage.getItem('org_Id')
      let ManagerId = localStorage.getItem('userId')
      let FromDate = dayjs(start).format('YYYY-MM-DDT00:00:00[Z]')
      let ToDate = dayjs(end).format('YYYY-MM-DDT00:00:00[Z]')

      cancelTokenSource = axios.CancelToken.source()

      axios
        .get(`${API_URL}/TotalProductiveHours/GetTotalProductiveHoursByReportManager`, {
          params: {
            OrganizationId,
            ManagerId,
            FromDate,
            ToDate,
          },
          cancelToken: cancelTokenSource?.token,
        })
        .then((res) => {
          setRecords(res.data)
          setSpinner(false)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      if (!start && !end) {
        setSpinner(false)
        return
      } else {
        let newEndDate: any
        if (end == null) {
          newEndDate = start
        } else {
          newEndDate = end
        }

        cancelTokenSource = axios.CancelToken.source()
        const body = {
          organizationId: localStorage.getItem('org_Id'),
          userId: id,
          fromDate: dayjs(start).format('YYYY-MM-DDT00:00:00[Z]'),
          toDate: dayjs(newEndDate).format('YYYY-MM-DDT00:00:00[Z]'),
        }

        axios
          .post(`${API_URL}/TotalProductiveHours/GetTotalProductiveHoursByUser`, body, {
            cancelToken: cancelTokenSource?.token,
          })
          .then((res) => {
            setRecords(res.data)
            setSpinner(false)
          })
          .catch((err) => {
            console.log(err)
            setSpinner(false)
          })
      }
    }
  }

  useEffect(() => {
    setSpinner(true)
    if (cancelTokenSource) {
      cancelTokenSource.cancel('New request initiated')
    }

    cancelTokenSource = axios.CancelToken.source()

    let OrganizationId = localStorage.getItem('org_Id')
    let ManagerId = localStorage.getItem('userId')
    let FromDate = dayjs().format('YYYY-MM-DDT00:00:00[Z]')
    let ToDate = dayjs().format('YYYY-MM-DDT00:00:00[Z]')

    if (!SelectedUser.selectedUserId) {
      setUserId('allMember')

      axios
        .get(`${API_URL}/TotalProductiveHours/GetTotalProductiveHoursByReportManager`, {
          params: {
            OrganizationId,
            ManagerId,
            FromDate,
            ToDate,
          },
          cancelToken: cancelTokenSource?.token,
        })

        .then((res) => {
          setRecords(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (SelectedUser.selectedUserId) {
      handleChange(SelectedUser.selectedUserId)
    }

    GetAssignee()
      .then((res) => {
        const newList = res.data.map((item: any) => {
          return {
            label: item.assigneeName,
            value: item.assigneeId,
          }
        })

        newList.unshift({
          label: 'All member',
          value: 'allMember',
        })

        setMembers(newList)
        setSpinner(false)
      })
      .catch((err) => {
        setSpinner(false)
        console.log(err)
      })
  }, [])

  //handle the change of the member
  const handleChange = (id: string) => {
    setSpinner(true)
    if (id === 'allMember') {
      setRole('manager')
      setUserId(id)
      dateRange(startDate, startDate, id)
    } else {
      setRole('user')
      setUserId(id)
      dateRange(startDate, endDate, id)
    }
    // id === 'allMember' ? setRole('manager') : setRole('user')
  }

  const createExcel = (e: any) => {
    e.preventDefault()
    const worksheetData = records.map((data) => {
      const fromDate = dayjs(data.fromDate).format('DD/MM/YYYY')
      const username = data.userName

      const productiveTime = dayjs()
        .startOf('day')
        .second(data.totalProductiveTime)
        .format('HH:mm:ss')

      const idleTime = dayjs().startOf('day').second(data.totalIdleTime).format('HH:mm:ss')
      const awayTime = dayjs().startOf('day').second(data.awayTime).format('HH:mm:ss')
      const unproductiveTime = dayjs()
        .startOf('day')
        .second(data.unproductiveTime)
        .format('HH:mm:ss')

      return [fromDate, username, productiveTime, idleTime, awayTime, unproductiveTime]
    })

    const name =
      userId === 'allMember'
        ? `${dayjs(records[0].fromDate).format('DD/MM/YYYY')}`
        : `${dayjs(startDate).format('DD/MM/YYYY')}${
            endDate
              ? `-${dayjs(endDate).format('DD/MM/YYYY')}`
              : `-${dayjs(startDate).format('DD/MM/YYYY')}`
          }`

    const worksheet = XLSXUtils.aoa_to_sheet([
      ['Date', 'Name', 'Productive Time', 'Idle Time', 'Away Time', 'Unproductive Time'],
      ...worksheetData,
    ])

    const workbook = XLSXUtils.book_new()
    XLSXUtils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    writeFile(workbook, `Productive vs Idle-${name}.xlsx`)
  }

  useEffect(() => {
    startDate ? setDateError(false) : setDateError(true)
  }, [startDate])

  const handleDateChange = (dates: any) => {
    if (userId === 'allMember') {
      setStartDate(dates)
      setEndDate(null)
      dateRange(dates, dates, userId)
    } else {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)
      dateRange(start, end, userId)
    }
  }

  return (
    <>
      <form className='form'>
        <div className='card-body'>
          <div className='tab-content pt-3'>
            <div>
              <div className='card-header border-0 pt-5'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 16px',
                    alignItems: 'center',
                  }}
                >
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Productive vs Idle</span>
                  </h3>
                  <div
                    style={{
                      marginRight: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      className='nav-container'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '58rem',
                        justifyContent: 'space-between',
                        paddingLeft: userId === 'allMember' ? '17rem' : '13rem',
                      }}
                    >
                      <div
                        style={{width: '200px'}}
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Search members'
                      >
                        <Select
                          className='selectDropdown'
                          components={makeAnimated()}
                          value={members.filter((option) => option.value === userId)}
                          options={members}
                          placeholder='select member'
                          onChange={(item: any) => {
                            if (
                              item.value !== SelectedUser.selectedUserId &&
                              item.value !== 'allMember'
                            ) {
                              dispatch(
                                userInfoActions.updateSelectedUser({
                                  selectedUserName: item.label,
                                  selectedUserId: item.value,
                                })
                              )
                            }
                            if (item.value === 'allMember') {
                              dispatch(
                                userInfoActions.updateSelectedUser({
                                  selectedUserName: undefined,
                                  selectedUserId: undefined,
                                })
                              )
                            }
                            handleChange(item.value)
                          }}
                          isClearable={false}
                          isSearchable={true}
                          closeMenuOnScroll={true}
                          isDisabled={members.length === 0}
                        />
                      </div>

                      {userId === 'allMember' ? (
                        <div className='date-picker-admin-team ' id='select-date'>
                          {SingleDatePickerComponent(
                            startDate,
                            handleDateChange,
                            'form-control ss_date'
                          )}

                          {dateError && (
                            <div className='fv-plugins-message-container'>
                              <div className='text-danger mt-2 mx-3'>Select a date</div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className='date-picker-manager' id='select-date'>
                          {MultiDateTimePicker(
                            startDate,
                            endDate,
                            handleDateChange,
                            'form-control ss_date'
                          )}

                          {dateError && (
                            <div className='fv-plugins-message-container'>
                              <div className='text-danger mt-2 mx-3'>Select a date</div>
                            </div>
                          )}
                        </div>
                      )}

                      <button
                        className={`btn btn-primary d-flex align-items-center ${
                          records.length == 0 && 'disabled'
                        }`}
                        style={{height: '3rem'}}
                        onClick={(e) => createExcel(e)}
                      >
                        <i className='fa fa-download'></i>Download
                      </button>
                    </div>
                  </div>
                </div>
                {spinner ? (
                  Loader('0px')
                ) : (
                  <div className='card-body py-3 mt-1'>
                    <div style={{overflow: records.length > 5 ? 'scroll' : ''}}>
                      {records.length === 0 && <h2 className='noRecordFound'>No Records Found</h2>}
                      {records.length > 0 && (
                        <ProChart className='' myObjects={records} role={role} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <style>
              {`
                .date-picker {
                  margin-left: 20px;
                }

                .react-datepicker__input-container>input {
                margin-right: 1.5rem;
                padding-left: 1rem;
            
                }
                .react-datepicker__close-icon {
                right: 1.5rem;
            }
          
          .form-select{
              width: 200px;
              border: 1px solid black;
              height: 37px;
              line-height: 1;
              margin-right: 1rem;
          }
          
          .productivityManager-select > div.selectDropdown{
            margin-right: 1.5rem;
            width: 15rem;
          }

              `}
            </style>
          </div>
        </div>
      </form>
    </>
  )
}
export {ProductivitysManager}
