import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'

import {AlertModal, passwordCreateResetForm} from '../../../CommonFunctions/CommonFunction'
import axios from 'axios'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CheckExpiry} from '../../../services/common.services'

const API_URL = process.env.REACT_APP_API_URL
let name: any

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const email = searchParams.get('email')

    const currentUrl = window.location.href
    const tokenPattern = /token=([^&]*)/
    const match = currentUrl.match(tokenPattern)
    let matchedToken = ''
    if (match) {
      matchedToken = match[1]
    }

    CheckExpiry(matchedToken).then((res: any) => {
      if (res.data === false) {
        AlertModal('Link expired!', '', 'error', false, '#7066E0', 'Ok').then(() => {
          navigate('/auth')
        })
      } else {
        name = searchParams.get('name')
        formik.setFieldValue('email', email)
        formik.setFieldValue('name', name)

        if (email) {
          searchParams.delete('email')
          searchParams.delete('name')
          setSearchParams(searchParams)
        }
      }
    })
  }, [])

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long.')
      .max(16, 'The password should not exceed 16 characters.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .matches(/[a-z]/, 'Password requires at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[^\w]/, 'Password must include at least one symbol.'),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'Must match "Password" field value'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        axios
          .post(`${API_URL}/Login/ResetPassword`, values)
          .then((res) => {
            AlertModal(res.data.message, '', 'success', false, '#7066E0', 'Ok').then(() => {
              if (res.data.success) {
                navigate('/auth')
              }
            })

            resetForm()
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            setSubmitting(false)

            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })

  return (
    <>
      <div
        className='d-flex justify-content-center align-items-center '
        style={{width: '100%', borderRadius: '20px 20px 0px 0px', paddingLeft: '3rem'}}
      >
        <img
          src={toAbsoluteUrl('/media/logos/mera_monitor_logo_bgWhite.svg')}
          style={{width: '35rem', padding: '3rem 1rem 1rem 1rem'}}
        ></img>
      </div>
      {passwordCreateResetForm(formik, 'Reset Your Password', name, hasErrors, loading, 'Send')}
    </>
  )
}
