import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import dayjs from 'dayjs'
import { Popup } from '../../../_metronic/partials/modals/ProVsIdle-Popup/ProVsIdlePopup'

interface MyObject {
  fromDate: string
  awayTime: string
  unproductiveTime: string
  organizationId: string
  toDate: string
  totalIdleTime: string
  totalProductiveTime: string
  userId: string
  userName: string
}

type Props = {
  myObjects: MyObject[]
  className: string
  role: string
  reCall?: (hasAnyChange: any) => void
}

const ProChart: React.FC<Props> = ({ className, myObjects, role, reCall = () => console.log("value not passed") }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedUser, setSelectedUser] = useState('')
  const [series, setSeries] = useState('')

  //console.log(myObjects)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        myObjects,
        role,
        setIsModalOpen,
        setSelectedDate,
        setSelectedUser,
        setSeries
      )
    )
    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [myObjects])

  const handleCloseModal = (hasAnyChange: any) => {
    reCall(hasAnyChange)
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [isModalOpen])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <style>
          {`
                .apexchartsmychart {
                 overflow-x:scroll;
                 overflow-y:hidden !important;
                 height:auto !important;
                 width:auto !important
                }

                body.modal-open{
                    overflow:hidden;
                }
          }
              `}
        </style>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ textAlign: 'left' }} />
        {isModalOpen && (
          <Popup
            onClose={handleCloseModal}
            barDate={selectedDate}
            selectedUser={selectedUser}
            series={series}
          ></Popup>
        )}
      </div>
    </div>
  )
}

export { ProChart }

function getChartOptions(
  height: number,
  myObjects: MyObject[],
  role: string,
  setIsModalOpen: (value: boolean) => void,
  setSelectedDate: (value: string) => void,
  setSelectedUser: (value: string) => void,
  setSeries: (value: string) => void
): ApexOptions {
  const borderColor = getCSSVariableValue('--bs-danger')

  let categories: any

  if (role === 'user') {
    categories = myObjects.map((myObject) =>
      dayjs(myObject.fromDate).locale('en').format('DD-MM-YYYY')
    )
  } else {
    categories = myObjects.map((myObject) => myObject.userName)
  }

  const productiveData = myObjects.map((myObject) => Number(myObject.totalProductiveTime) / 3600)
  const idleData = myObjects.map((myObject) => Number(myObject.totalIdleTime) / 3600)
  const awayData = myObjects.map((myObject) => Number(myObject.awayTime) / 3600)
  const unproductiveData = myObjects.map((myObject) => Number(myObject.unproductiveTime) / 3600)
  const userData = myObjects.map((myObject) => myObject.userId)
  const dateData = myObjects.map((myObject) => myObject.fromDate)

  return {
    series: [
      {
        name: 'Productive',
        data: productiveData.map((val) => Number(val)),
      },
      {
        name: 'Idle',
        data: idleData.map((val) => Number(val)),
      },
      {
        name: 'Away',
        data: awayData.map((val) => Number(val)),
      },
      {
        name: 'Unproductive',
        data: unproductiveData.map((val) => Number(val)),
      },
    ],
    chart: {
      id: 'mychart',
      fontFamily: 'inherit',
      stacked: true,
      type: 'bar',
      height: '515px',
      toolbar: {
        show: false,
      },
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          if (config.w.config.series[config.seriesIndex].name !== 'Away') {
            event.target.style.cursor = 'pointer'
          }
        },
        dataPointSelection(e, chart, options) {
          if (options.seriesIndex === 2) {
            return
          }
          if (e.button === 2) {
            return
          }
          setSelectedDate(dateData[options.dataPointIndex])
          setSelectedUser(userData[options.dataPointIndex])

          switch (options.seriesIndex) {
            case 0:
              setSeries('productive')
              break
            case 1:
              setSeries('idle')
              break
            case 2:
              setSeries('idle')
              break
            case 3:
              setSeries('unproductive')
              break
            default:
              setSeries('')
              break
          }

          setIsModalOpen(true)
        },
      },
      width: categories.length > 10 ? 100 * categories.length : '100%',
      zoom: {
        enabled: categories.length > 10,
        type: 'x',
        autoScaleYaxis: true,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4,
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth:
          categories.length < 4
            ? '15px'
            : categories.length > 10
              ? '65px'
              : categories.length > 4
                ? '50px'
                : '30px',
        barHeight: '0.5%',
        borderRadius: 2,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,

      formatter: function (val: any) {
        const changeVal = Math.round(val * 3600)

        return dayjs().startOf('day').second(changeVal).format('HH:mm:ss')
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
        color: 'black',
      },
      axisTicks: {
        show: false,
        color: 'black',
      },
      labels: {
        style: {
          colors: 'black',
          fontSize: '12px',
        },
        rotate: 0,
        hideOverlappingLabels: false,
        trim: true,
      },
    },
    yaxis: {
      // max: 24,
      labels: {
        style: {
          colors: 'black',
          fontSize: '12px',
        },
        formatter: function (val) {
          if (val != Infinity) {
            const roundedVal = Math.round(val)
            return roundedVal.toString()
          } else {
            return '1'
          }
        },
      },
      axisBorder: {
        show: true,
        color: 'black',
      },
      forceNiceScale: true,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          const changeVal = Math.round(val * 3600)

          return dayjs().startOf('day').second(changeVal).format('HH:mm:ss')
        },
      },
    },
    colors: ['#34A853', '#FBBC04', '#9D6B50', '#FF6178'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  }
}
